// This file configures the initialization of Sentry on the client.
// The config added here will be used whenever a users loads a page in their browser.

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"e6d5c80f9740c5956a22e7e5698058bde6d25336"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

if (process.env.NEXT_PUBLIC_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.75,
    debug: false,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  });
}
